import axios from 'axios';


// GK 2023-10-22 - gets coinbase authentication token
export const getGeminiAccessToken = (code, redirect_uri) => {
    
  return axios.get(`/api/gemini/access-token?code=${code}&redirect_uri=${redirect_uri}`);
};

// GK 2023-10-22 - gets user data from coinbase
// GK 2023-10-22 - Note: this API call is now deprecated, I may want to change this
// export const getUserData = (coinbaseAuthToken) => {
    
//   return axios.get('/api/coinbase/user', {
//       headers: {
//         'Coinbase-Access-Token': coinbaseAuthToken
//       }
//     }).then(response => response.data);
// };

// export const getAccountData = (coinbaseAuthToken, currency) => {
//   const apiURL = currency ? `/api/coinbase/accounts/${currency}` : '/api/coinbase/accounts';

//   return axios.get(apiURL, {
//     headers: {
//       'Coinbase-Access-Token': coinbaseAuthToken
//     }
//   }).then(response => response.data);
// };


export const getGeminiAddresses = async (geminiAuthToken, network) => {
  return axios.get(`/api/gemini/addresses/${network}`, {
    headers: {
      'Gemini-Access-Token': geminiAuthToken
    }
  }).then(response => response.data);
};


// GK 2023-10-22 - creates an address, we use this to get the SOLUSDC address, 
// because we cant get it with the getAddresses() call above (you get the ethUSDC address) 
// https://docs.cdp.coinbase.com/sign-in-with-coinbase/docs/api-addresses/
// export const createAddress = (coinbaseAuthToken, walletId, data = {}) => {
//   return axios.post(`/api/coinbase/accounts/${walletId}/addresses`, data, {
//     headers: {
//       'Coinbase-Access-Token': coinbaseAuthToken
//     }
//   }).then(response => response.data);
// }