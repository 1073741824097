// FIREBASE
export const AUTH_TOKEN = "Auth-Token";
export const AUTH_REFERESH_TOKEN = "Auth-Referesh-Token";
export const LAST_VISITED_LOCATION = 'Last-Visited-Location';

// COINBASE
export const Coinbase_Auth_Token = "Coinbase Auth Token";
export const Coinbase_Refresh_Token = "Coinbase Refresh Token";
export const COINBASE_REDIRECT_URI = process.env.REACT_APP_ENV === 'development' ? 'https://localhost:3000/crypto-login' : 'https://punkypay.com/crypto-login';
export const COINBASE_WWW_REDIRECT_URI = process.env.REACT_APP_ENV === 'development' ? 'https://www.localhost:3000/crypto-login' : 'https://www.punkypay.com/crypto-login';

// GEMINI
export const Gemini_Auth_Token = "Gemini Auth Token";
export const Gemini_Refresh_Token = "Gemini Refresh Token";
export const GEMINI_REDIRECT_URI = process.env.REACT_APP_ENV === 'development' ? 'https://localhost:3000/crypto-login' : 'https://punkypay.com/crypto-login';
export const GEMINI_WWW_REDIRECT_URI = process.env.REACT_APP_ENV === 'development' ? 'https://www.localhost:3000/crypto-login' : 'https://www.punkypay.com/crypto-login';
