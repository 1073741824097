import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Space} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth-context';

// import './styles/Home.css';

const UnderstandTXNDetails = () => {
    return (
        <div>
            <Row justify="left">
                <h1 className="auth-title-text"> Understanding the Transaction Details Page </h1>
                <p className="auth-title-text"> <b> Transaction Memo: </b> This field shows memo details, which are entered when the user makes the transaction. These details cannot be edited after the transaction is sent. </p>
                <p className="auth-title-text"> <b> Sender's Notes: </b> This field shows notes which the sender adds to the transaction. The sender can update these notes at any time. For instance, the sender could add their purchase order to this field. </p>
                <p className="auth-title-text"> <b> Recipient's Notes: </b> This field shows notes which the recipeint adds to the transaction. For instance, the recipient can add a receipt or tracking number to this field.   </p>
                <p className="auth-title-text"> <b> Sender: </b> This is the sender's email which they use to sign into Punkypay with. </p>
                <p className="auth-title-text"> <b> Recipient: </b> This is the recipient's email which they use to sign into Punkypay with. </p>
                <p className="auth-title-text"> <b> Amount Sent: </b> This is the amount of money which the recipient will receive. </p>
                <p className="auth-title-text"> <b> Network Fees: </b> When you send money on a network, the network charges fees. This is the amount the sender was charged to make this transaction. </p>
                <p className="auth-title-text"> <b> Total: </b> This is the Amount Sent plus any Network Fees. </p>
                <p className="auth-title-text"> <b> Currency: </b> This is the currency which was sent, eg. USDC, TUSD, Eth, etc. </p>
                <p className="auth-title-text"> <b> Network: </b> This is the network which this money was sent on. This could be solana, ethereum, stellar, etc. </p>
                <p className="auth-title-text"> <b> Transaction ID (will be shown if Transaction Status = complete): </b> This shows the Transaction ID for the transaction. This Transaction ID is verifiable on the blockchain's scanning tool, like solscan.io or etherscan.io </p>
                <p className="auth-title-text"> <b> Senders Crypto Address: </b> This field shows the sender's crypto address. If you were to manually return the money to the sender from your exchange, you would send it to this address. </p>
                <p className="auth-title-text"> <b> Recipient's Crypto Address: </b> This field shows the recipient's crypto address.</p>
                <p className="auth-title-text"> <b> Transaction Initiated At: </b> This is the time which the transaction was initiated. It usually takes a few minutes before the transaction is complete. </p>
                <p className="auth-title-text"> <b> Transaction Info Updated At: </b> This is when the transaction details were last updated. We check on these transactions once every minute, <i>as long as</i> the sender stays signed into Coinbase on Punkypay. </p>
                <p className="auth-title-text"> <b> Transaction Status: </b> This is the transaction status, according to the sender's crypto exchange. This field will only update when the sender stays signed into Coinbase on Punkypay. If the transaction status has not changed in a while, the sender likely logged out of Punkypay (and Coinbase) immediately after making the transaction. This field will start updating again once the sender signs back into the Coinbase account which they made this transaction from.  </p>
            </Row>
        </div>
    )
}

export default UnderstandTXNDetails;