import { useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Form, Input, message, Alert, Col, Row } from "antd";
import { resetPassword } from "../../firebase/firebase";

// GK 2023-10-22 - Creates password reset component
const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState('');

  // GK 2023-10-22 Defines what happens when you press "submit"
  const onFinish = async () => {
    try {
      console.log('Email =>', email);
        await resetPassword(email);
        setEmailMessage(true);
        setError('');
      } catch (err) {   
        if (err.code === 'auth/user-not-found') {
          setError('User not found. Try again!')
          return;
        }
        setError('Something went wrong. Try again!');
      }
  }

  // GK 2023-10-22 - The errorInfo is automatically supplied by AntDesign
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  
  return (
    <div>
      <h2 style={{textAlign: 'center'}}>Reset Password</h2>
      <Row style={{marginBottom: '10px'}}>
        <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
            {
              emailMessage && 
              (<Alert 
                message={`Email sent to ${email}. Don't forget to check your spam!`} 
                type="success" 
              />)
            }
        </Col>
        <Col xs={{span: 24}} md={{span: 16, offset: 4}}>          
            {
              error && 
              (<Alert 
                message={error} 
                type="error" 
              />)
            }
        </Col>
      </Row>
      <Form
        name="basic"
        labelCol={{
          xs: {span: 24},
          md: {span: 8}
        }}
        wrapperCol={{
          xs: {span: 24},
          md: {span: 8},
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Enter the Email"
          name="email"
          value={email}
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
          className="form-item"
        >
          <Input onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>

        <Form.Item
          className="form-item form-item__submit"
          wrapperCol={{
            md: {
              offset: 8,
              span: 16,
            },
            xs: {
              span: 24,
            },
          }}
        >
          <Button type="primary" htmlType="submit">
            Reset Password
          </Button>
        </Form.Item>
      </Form>
      <Row>
        <Col 
          md={{
            offset: 8,
            span: 16,
          }}
          xs={{
            span: 24
          }}
        >
          <Link className="text-link" to="/login">Go to Login</Link>
        </Col>
      </Row>
    </div>
  );
};

export default PasswordReset;
