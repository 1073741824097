    //Needs to be moved to a spearate file, is used in crypto login status too
    export const pad = (n) => n < 10 ? `0${n}` : n;

    //Needs to be moved to a spearate file, is used in crypto login status too
    export const formatSeconds = (secs) => {
        const pad = (n) => n < 10 ? `0${n}` : n;
    
        const h = Math.floor(secs / 3600);
        const m = Math.floor(secs / 60) - (h * 60);
        const s = Math.floor(secs - h * 3600 - m * 60);
    
        return `${pad(h)}:${pad(m)}:${pad(s)}`;
    }

    export const toCamelCase = (str) => {
        // note: this is remobing spaces between words as well
        return str.replace(/[-_]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
      }