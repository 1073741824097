import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// 2023-10-24 GK - creates a thunk to get the coinbase user auth
// Redux Thunk is middleware that allows you to return functions, rather than just actions, within Redux. This allows for delayed actions, including working with promises. One of the main use cases for this middleware is for handling actions that might not be synchronous, for example, using axios to send a GET request
export const getCoinbaseUserAuth = createAsyncThunk('coinbase/getCoinbaseUserAuth', 
    (coinbaseAccessToken) => {
        return axios.get('/api/coinbase/user/auth', {
            headers: {
              'Coinbase-Access-Token': coinbaseAccessToken
            }
        }).then(response => response.data);
});
