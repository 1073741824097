import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row, Alert, Spin, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { storeCryptoAccountInMongo } from "../rest/cryptoAccount";
import { getCoinbaseUserAuth } from '../store/actions/coinbase.action';
import {
  getAccountData,
  getUserData,
  createAddress,
  getCoinbaseToken
} from "../rest/crypto-api/coinbase";
import {
  getGeminiAccessToken,
  getGeminiAddresses
} from "../rest/crypto-api/gemini";

import CryptoAccountOTPConfirmation from "./CryptoAccountOTPConfirmation";
import {
  Coinbase_Auth_Token, 
  COINBASE_REDIRECT_URI, 
  COINBASE_WWW_REDIRECT_URI,
  Gemini_Auth_Token,
  GEMINI_REDIRECT_URI,
  GEMINI_WWW_REDIRECT_URI
} from "../const/const";
import { getCryptoAccount } from "../store/actions/cryptoAccount.action";
import { useAuth } from "../contexts/auth-context";
import { useAppBreakpoint } from "../hooks/breakpoints";

import './styles/Home.css';

const host = window.location.hostname.split('.')[0];
/**************************COINBASE OAUTH2 DETAILS************************************/
// for production
const redirect_uri = host === 'www' ? COINBASE_WWW_REDIRECT_URI : COINBASE_REDIRECT_URI;
// for local development
// const redirect_uri = "https://localhost:3000/crypto-login";
const client_id =
  "9975e0512b7fd2a94e7cc99e750463de52554ac5bb8b1a4b51d6f11ec6f91cdd";
const client_secret =
  "3fcf5a774c770533852e28e20a8f8d6d433ada6d5307de64006a917c6111be6c";

const COINBASE_AUTH_BASE = "https://www.coinbase.com/oauth";
const scope =
  "wallet:user:read,wallet:user:email,wallet:user:update,wallet:accounts:read,wallet:transactions:read,wallet:transactions:send,wallet:addresses:read,wallet:addresses:create,wallet:payment-methods:read,wallet:withdrawals:create,wallet:withdrawals:read,wallet:deposits:create,wallet:deposits:read,wallet:orders:create,wallet:orders:read";
//  "wallet:accounts:create,wallet:accounts:delete,wallet:accounts:read,wallet:accounts:update,wallet:addresses:create,wallet:addresses:read,wallet:buys:create,wallet:buys:read,wallet:checkouts:create,wallet:checkouts:read,wallet:contacts:read,wallet:deposits:create,wallet:deposits:read,wallet:notifications:read,wallet:orders:create,wallet:orders:read,wallet:orders:refund,wallet:payment-methods:delete,wallet:payment-methods:limits,wallet:payment-methods:read,wallet:sells:create,wallet:sells:read,wallet:supported-assets:read,wallet:trades:create,wallet:trades:read,wallet:transactions:read,wallet:transactions:request,wallet:transactions:send,wallet:transactions:transfer,wallet:user:email,wallet:user:read,wallet:user:update,wallet:withdrawals:create,wallet:withdrawals:read"

  const coinbaseState = "134ef5504a94";
// user can enter real amount they want to send, will need to prompt them on this
const PARAM = `response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${coinbaseState}&scope=${scope}&account=select&account_currency=USDC&meta[send_limit_amount]=1.00&meta[send_limit_period]=day`;
const COINBASE_AUTH_URL = `${COINBASE_AUTH_BASE}/authorize?${PARAM}`;
/**************************COINBASE OAUTH2 DETAILS************************************/

/**************************GEMINI OAUTH2 DETAILS************************************/
// for production
const geminiRedirectUri = host === 'www' ? GEMINI_WWW_REDIRECT_URI : GEMINI_REDIRECT_URI;
// for local development
// const redirect_uri = "https://localhost:3000/crypto-login";
const geminiClientId =
  "66afb5f7-2984-4a68-a948-9c0dd786c27b";
const geminiClientSecret =
  "66afb5f7-2151-402a-a73f-ce94a8fc4678";

const GEMINI_AUTH_BASE = "https://exchange.gemini.com";
const geminiScope =
  "addresses:read, history:read, account:read, orders:read, payments:read, balances:read, banks:read, clearing:read";

  const geminiState = "134e4253yetd67";
// user can enter real amount they want to send, will need to prompt them on this
const GEMINI_PARAM = `response_type=code&client_id=${geminiClientId}&redirect_uri=${geminiRedirectUri}&state=${geminiState}&scope=${geminiScope}`;
const GEMINI_AUTH_URL = `${GEMINI_AUTH_BASE}/auth?${GEMINI_PARAM}`;
/**************************COINBASE OAUTH2 DETAILS************************************/
// GET https://exchange.gemini.com/auth?client_id=my_id&response_type=code&redirect_uri=www.example.com/redirect&state=82350325&scope=balances:read,orders:create
// GK 2023-10-22 - logs in to Coinbase or Gemini, etc. 
const CryptoLogin = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [coinbaseUser, setCoinbaseUser] = useState(null);
  const [coinbaseWallet, setCoinbaseWallet] = useState(null);
  const [coinbaseLoading, setCoinbaseLoading] = useState(false);
  const [geminiLoading, setGeminiLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOpenCoinbaseModal, setIsOpenCoinbaseModal] = useState(false);
  const [isOpenGeminiModal, setIsOpenGeminiModal] = useState(false);
  const [isOpenManualAddressModal, setIsOpenManualAddressModal] = useState(false);

  const [showOtpField, setShowOtpField] = useState(false);
  
  const storage = localStorage;
  const urlParams = new URLSearchParams(window.location.search);
  const [code, setCode] = useState(urlParams.get("code"));
  const [authState, setAuthState] = useState(urlParams.get("state"));
  const loginEmail = auth.currentUser?.email;

  const [coinbaseAuthToken, setCoinbaseAuthToken] = useState();
  const [geminiAuthToken, setGeminiAuthToken] = useState();
  const dispatch = useDispatch();
  const coinbaseLoginButtonRef = useRef(); // A ref for actual Coinbase button link
  const geminiLoginButtonRef = useRef(); // A ref for actual Gemini button link
  const isMediumScreen = useAppBreakpoint('md');

  // TODO: remove this after your testing
  // useEffect(() => {
  //   console.log('NAVIGATE')
  //   navigate("/transactions");
  // }, []);

  const handleModalOk = (exchange) => {
    if(exchange === 'coinbase') {
      setIsOpenCoinbaseModal(false);
      coinbaseLoginButtonRef.current?.click();
    }
    if(exchange === 'gemini') {
      setIsOpenGeminiModal(false);
      geminiLoginButtonRef.current?.click();
    }
  }

  const storeGeminiAccount = async (accessToken) => {
    try {
      const network = 'ethereum';
      const response = await getGeminiAddresses(accessToken, network);
      const address = response[0]?.address;
      if(address) {

        const user = {
          id: address
        }        
        storeCryptoAccountInMongo(
          address,
          'gemini',
          user,
        ).then((res) => {
          if(res.status === 201 || res.status === 200) {
            setError(null);
            // Refetch the user crypto-account info
            dispatch(getCryptoAccount()).then(() => {
              navigate("/transactions"); // Navigate to the transactions page after dispatch is successful
            })
          }
        })
        .catch((err) => {
          let errorMessage = 'Something went wrong logging in or saving the Gemini account. Try again!';
    
          if(err?.response?.data?.errorCode === 'VERIFY_OTP') {
            errorMessage = `OTP sent to ${loginEmail}. Don't forget to check your spam!`;
            setShowOtpField(true);
          }
    
          setError(errorMessage);
          setGeminiLoading(false);
        });
      }
    } catch (error) {
      setError('Something went wrong getting your access token from Gemini. Please try again!');
      setGeminiLoading(false);
    }
  }

  // GK 2023-10-22 - this is the script to save Coinbase data into the CryptoAccount databse
  const storeCoinbaseAccount = (address) => {
    if(!loginEmail || !coinbaseUser || !coinbaseWallet || !address) {
      return;
    }
    // GK 2023-11-06 we shoudl edit the above to give an error message if it fails for any reason
    
    const user = {
      id: coinbaseUser?.id,
      email: coinbaseUser?.email,
      exchange_status: coinbaseUser?.tiers?.completed_desciption,
    }

    const wallet = {
      balance: coinbaseWallet?.balance?.amount,
      id: coinbaseWallet.id 
    }
    
    storeCryptoAccountInMongo(
      address,
      'coinbase',
      user,
      wallet,
    ).then((res) => {
      if(res.status === 201 || res.status === 200) {
        setError(null);
        // Refetch the user crypto-account info
        dispatch(getCryptoAccount()).then(() => {
          navigate("/transactions"); // Navigate to the transactions page after dispatch is successful
        })
      }
    })
    .catch((err) => {
      let errorMessage = 'Something went wrong logging in or saving the Coinbase account. Try again!';

      if(err?.response?.data?.errorCode === 'VERIFY_OTP') {
        errorMessage = `OTP sent to ${loginEmail}. Don't forget to check your spam!`;
        setShowOtpField(true);
      }

      setError(errorMessage);
      setCoinbaseLoading(false);
    });
  }

  const createCoinbaseAddress = async (coinbaseToken, coinbaseWalletId, data) => {
    try {
      const res = await createAddress(coinbaseToken, coinbaseWalletId, data);
      console.log('createAddress ===>', res);
      if(res?.address) {
        storeCoinbaseAccount(res.address);
      }
    } catch (error) {
      if(error.response?.data?.errors[0]?.id === 'invalid_request') {
        const errorMessage = <div>
          Something went wrong when signing you into Coinbase. Either you lost connection, or more likely you do not have a Level 3 account.&nbsp;
           <a href="https://help.coinbase.com/en/coinbase/trading-and-funding/buying-selling-or-converting-crypto/how-do-i-raise-my-limits" target="_blank">
            Click here to see a video on how to resolve this problem.
          </a>
        </div>
        setError(errorMessage);
      } else {
        setError('You were unable to sign in. Please try signing-in again.')
      }
      setCoinbaseLoading(false);
    }
  }

  // useEffect(() => {
  //   let authToken = storage.getItem(AUTH_TOKEN);

  //   if (!authToken) {
  //     navigate("/login");
  //   }
  // }, []);

  useEffect(() => { // Step 3
    if(coinbaseAuthToken && coinbaseUser && coinbaseWallet) {
      console.log('Coinbase User =>', coinbaseUser);
      console.log('Coinbase Wallet ==>', coinbaseWallet);

    //  const data = {
    //   name: 'Punkypay Solana USDC Address',
    //   network: 'solana'
    // }
    // GK June 2024 : 
    // Now, we should have one for Ethereun
    const data = {
      name: 'Punkypay Ethereum USDC Address',
      network: 'ethereum'
    }

    // GK 2023-10-22 - creates Solana USDC address, 
    // GK June 2024 - We will want to update this to get an ETHUSDC address
    // we create the address rather than get it from addresses because this is the only way we can consistently retrieve this address
    createCoinbaseAddress(coinbaseAuthToken, coinbaseWallet.id, data);
    }
   }, [coinbaseAuthToken, coinbaseUser, coinbaseWallet]);

  // GK 2023-10-22 - gets the Coinbase Access Token for coinbase api requests
  const getCoinbaseTokenFromCode = async (code) => {
    try {
      const response = await getCoinbaseToken(code, redirect_uri);

      storage.setItem(Coinbase_Auth_Token, response.data.access_token);
      setCoinbaseAuthToken(response.data.access_token);
    } catch (error) {
      setError('Something went wrong getting your access token from Coinbase. Please try again!');
      setCoinbaseLoading(false);
    }
  };

  const getGeminiTokenFromCode = async (code) => {
    try {
      const response = await getGeminiAccessToken(code, redirect_uri);
      storage.setItem(Gemini_Auth_Token, response.data.access_token);
      setGeminiAuthToken(response.data.access_token);
    } catch (error) {
      setError('Something went wrong getting your access token from Gemini. Please try again!');
      setGeminiLoading(false);
    }
  }

  useEffect(() => { // Step 1
    console.log('Auth State =>', authState);
    if (code && authState === coinbaseState && !coinbaseAuthToken) {
      setCoinbaseLoading(true);
      getCoinbaseTokenFromCode(code);
    }
    if (code && authState === geminiState && !geminiAuthToken) {
      setGeminiLoading(true);
      getGeminiTokenFromCode(code);
    }
  }, [code, authState]); // GK 2023-10-22 - run this if the code changes

  useEffect(() => { // Step 2
    if (coinbaseAuthToken) {
      if (coinbaseUser === null) {
        getUserData(coinbaseAuthToken).then((res) => {
          console.log('getUserData =>>>>>>>', res);
          setCoinbaseUser(res);
        });
      }
      if (coinbaseWallet === null) {
        getAccountData(coinbaseAuthToken, 'USDC')
          .then((res) =>{
            console.log('getAccountData (CoinBase Wallets) =>>>>', res);
            const coinBaseWalletAccount = res;
          
            if(coinBaseWalletAccount) {
              if(coinBaseWalletAccount.balance.currency !== 'USDC') {
                setError(
                  `There was a server side issue authenticating with Coinbase.
                  We expected to see your USD Coin wallet but got a different one. Please try logging in again.`
                );
                setCoinbaseLoading(false);
                return;
              }
              setCoinbaseWallet(coinBaseWalletAccount);
            }
        })
        .catch((err) => {
          let errorMessage = err?.message ?? 'Something went wrong. Try again!';
          if(err.code === 'ERR_NETWORK') {
            errorMessage = 'Coinbase seems not to be responding. Try again later!';
          }
          if(err.response?.data?.errorCode) {
            errorMessage = err.response.data.message;
          }
          setError(errorMessage);
          setCoinbaseLoading(false);
        })
      }

      dispatch(getCoinbaseUserAuth(coinbaseAuthToken));
    }
  }, [coinbaseAuthToken]);

  useEffect(() => {
    if(geminiAuthToken) {
      storeGeminiAccount(geminiAuthToken);
    }
  }, [geminiAuthToken])

  if(showOtpField) {
    return <CryptoAccountOTPConfirmation defaultError={error} />;
  }

  return (
    <div style={{paddingTop: '40px'}}>
      {
        error && (
          <Row style={{marginBottom: '10px', marginTop: '10px'}}>
            <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
              <Alert
                message={error}
                type="error"
              />
            </Col>
          </Row>
        )
      }
      {
        coinbaseLoading && (
          <Row style={{marginBottom: '10px', marginTop: '10px'}}>
            <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
              <Spin spinning={coinbaseLoading} size="large">
                <Alert
                  message="Coinbase authentication in progress"
                  description="You will be redirected back to transactions page shortly."
                  type="info"
                />
              </Spin>
            </Col>
          </Row>
        )
      }
      {
        geminiLoading && (
          <Row style={{marginBottom: '10px', marginTop: '10px'}}>
            <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
              <Spin spinning={geminiLoading} size="large">
                <Alert
                  message="Gemini authentication in progress"
                  description="You will be redirected back to transactions page shortly."
                  type="info"
                />
              </Spin>
            </Col>
          </Row>
        )
      }
      {
        !coinbaseLoading && !geminiLoading && (
          <>
          <Row justify="center" gutter={[16, 14]}>
            {/* coinbase color style={{ backgroundColor: '#0667D0'}}  */}
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Button type="primary" disabled={coinbaseLoading} block onClick={() => setIsOpenCoinbaseModal(true)}>
                Sign in with Coinbase
              </Button>
              <p class="auth-title-text" justify="center"><i>Send and Receive Money</i></p>
              <br></br>
            </Col>
            {/* gemini color style={{ backgroundColor: '#00DCFA'}} */}
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Button type="primary" disabled={geminiLoading} block onClick={() => setIsOpenGeminiModal(true)}>
                Sign in with Gemini
              </Button>
              <p class="auth-title-text" justify="center"><i>Receive Money Only</i></p>
              <br></br>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Button type="primary" disabled={coinbaseLoading} block onClick={() => setIsOpenManualAddressModal(true)}>
                Punkypay Mailroom
              </Button>
              <p class="auth-title-text" justify="center"><i>Receive Money Only</i></p>
              <br></br>
            </Col>


            {/* <Col span={12} offset={6}>
              {
                error && (<Alert message={error} type="error" />)
              }
            </Col> */}
          </Row>
          <Row justify="center" gutter={[16, 14]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <p class="auth-title-text" justify="center"> Video showing <a class="light-blue" href="https://youtu.be/WaPv0scN3-c" target="_blank">how to use</a> Coinbase on Punkypay. </p>
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 14]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <p class="auth-title-text" justify="center"> Video showing <a class="light-blue" href="https://youtu.be/cnFqZeTN-sM" target="_blank">how to get</a> USDC in Coinbase.</p>
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 14]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <p class="auth-title-text" justify="center"> Video showing <a class="light-blue" href="https://youtu.be/2YxH4mbsI8U" target="_blank">how to use</a> Punkypay Mailroom.</p>
            </Col>
          </Row>
          {/* <Row justify="center" gutter={[16, 14]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <p class="auth-title-text" justify="center"> Document explaining <a class="light-blue" href="https://docs.google.com/document/d/1-d-72szMeYRttwbosVMXTZlVRVHZe3cUi_NWXc-gI60/edit#heading=h.r1kdh7jwjoxk" target="_blank">how Punkypay</a> works. </p>
            </Col>
          </Row> */}
          </>
        )
      }
      <Modal // Info Modal for Manually inputted address
        title="Notice about Punkypay Mailroom:"
        centered
        open={isOpenManualAddressModal}
        onOk={() => navigate("/manual-address")}
        okText="Proceed"
        onCancel={() => setIsOpenManualAddressModal(false)}
        width={700} // width in px
      >
        <p>1) You can only receive money in the Punkypay Mailroom, not send it.</p>
        <p>2) Ensure that you are entering an Ethereum USDC address.</p>
        <p>3) Ensure that you have tested this address works.</p>
      </Modal>
      <Modal // Info Modal for Coinbase Signin
        title="Notice about Coinbase:"
        centered
        open={isOpenCoinbaseModal}
        onOk={() => handleModalOk('coinbase')}
        okText="Proceed"
        onCancel={() => setIsOpenCoinbaseModal(false)}
        width={700} // width in px
      >
        <p> 
          On the following screen, you will need to click "Allow access". 
        </p>
        <p> At the moment, you will have to use Coinbase to ensure that you have enough USDC available to send. In the future, you will be able to manage Coinbase's USDC account within Punkypay.  </p>
        <p> If you have any troubles with this, please watch <a class="light-blue" href= "https://youtu.be/WaPv0scN3-c" target="_blank">this video from 1:05.</a>  </p>
      </Modal>
      <Modal // Info Modal for Gemini Signin
        title="Notice about Gemini:"
        centered
        open={isOpenGeminiModal}
        onOk={() => handleModalOk('gemini')}
        okText="Proceed"
        onCancel={() => setIsOpenGeminiModal(false)}
        width={700} // width in px
      >
        <p> 
          You are currently allowed to receive money by signing into Gemini, but you are not allowed to send money. Currently, you can only send money by signing in with Coinbase. 
        </p>
      </Modal>
      <Button
      // FOR COINBASE 
      // Hidden with ref since we can only trigger this from the Modal Ok button. Check "handleModalOk"
        style={{visibility: 'hidden'}} 
        type="primary" 
        ref={coinbaseLoginButtonRef} // Ref used in triggering this button link via "handleModalOk"
        disabled={coinbaseLoading} 
        block href={COINBASE_AUTH_URL}
        >
          COINBASE HIDDEN BUTTON FOR REASON ABOVE
      </Button>
      <Button
      // FOR GEMINI 
      // Hidden with ref since we can only trigger this from the Modal Ok button. Check "handleModalOk"
        style={{visibility: 'hidden'}} 
        type="primary" 
        ref={geminiLoginButtonRef} // Ref used in triggering this button link via "handleModalOk"
        disabled={geminiLoading} 
        block href={GEMINI_AUTH_URL}
        >
          GEMINI HIDDEN BUTTON FOR REASON ABOVE
      </Button>
    </div>
  );
}

export default CryptoLogin;
