import {createSlice} from '@reduxjs/toolkit';
import {getCryptoAccount} from '../actions/cryptoAccount.action';
import { PURGE } from 'redux-persist';


// 2023-10-24 GK - creates a reducer to manage the cryptoAccount state

const initialState = {
    account: {
        data: null,
        status: 'idle',
        error: null
    },

};

export const cryptoAccountSlice = createSlice({
    name: 'cryptoAccount',
    initialState,
    reducers: {
        
    },
    extraReducers(builder){
        builder
            .addCase(getCryptoAccount.pending, (state, action) => {
                state.account.status = 'loading'
            })
            .addCase(getCryptoAccount.fulfilled, (state, action) => {
                state.account.status = 'succeeded';
                state.account.error = null;
                state.account.data = action.payload;
            })
            .addCase(getCryptoAccount.rejected, (state, action) => {
                state.account.data = null;
                state.account.status = 'failed';
                state.account.error = action.error.message
            })
            .addCase(PURGE, () => {
                return initialState;
            })
    }
});

export default cryptoAccountSlice.reducer;