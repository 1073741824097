// This the way we access firebase from the frontend

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Replace it with env var to protect keys
const config = {
  apiKey: "AIzaSyC-8KKEHefm98JRmKrgBZpdsA9Jp8Glzw0",
  authDomain: "punkypay-one.firebaseapp.com",
  projectId: "punkypay-one",
  storageBucket: "punkypay-one.appspot.com",
  messagingSenderId: "95183095477",
  appId: "1:95183095477:web:36d939087195fdb5e260fc",
  measurementId: "G-V3X2MMJ8GG"
}; 

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error('No Firebase configuration object provided.' + '\n' +
    'Add your web app\'s configuration object to firebase-config.ts');
  } else {
    return config;
  }
}
// console.log(process.env, "process.env")
// const firebaseConfig = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.MSI,
//   appId: process.env.APP_ID,
//   measurementId: process.env.MEASUREMENT_ID,
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);