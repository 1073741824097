import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from 'antd';
import moment from 'moment';

import { useAuth } from "../../contexts/auth-context";
import './styles/TransactionListDisplay.css';
const gridStyle = { width: '100%'};


const TransactionListDisplay = (props) => {
    const { transactions, inProgress } = props;
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [groupedTransactionsData, setGroupedTransactionsData] = useState([]);
    const auth = useAuth();
    const loginEmail = auth.currentUser?.email;

    useEffect(() => {
        function handleOnline() {
          setIsOnline(true);
        }
    
        function handleOffline() {
          setIsOnline(false);
        }
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        // Clean up event listeners on component unmount
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };
      }, []);

    useEffect(() => {
        const rows = (transactions || []).map((transaction) => {
          const date = moment(transaction.created_at).format('ll');
          const transactionAmount = transaction.txn_amount ? Number(transaction.txn_amount).toFixed(2) : "";
          const recipientField = loginEmail === transaction.recipient_login_email ? transaction.sender_login_email : transaction.recipient_login_email;
          return {
            key: transaction._id,
            recipient: recipientField,
            memo: transaction.memo_at_txn,
            transactionDate: `${date}`,
            amount: transactionAmount,
            transactionFee: transaction.blockchain_fees,
            status: transaction.txn_status,
            transactionId: transaction.exchange_txn_id
          }
        });

        const groupedTransactions = (transactions || []).reduce((prevResult, transaction) => {
            const date = moment(transaction.created_at).format('ll');
            const transactionAmount = transaction.txn_amount ? Number(transaction.txn_amount).toFixed(2) : '';
            const recipientField = loginEmail === transaction.recipient_login_email ? transaction.sender_login_email : transaction.recipient_login_email;

            const transactionType = transaction.recipient_login_email.toLowerCase() === loginEmail.toLowerCase() ? 'recipient' : 'sender';
            const transactionPrefix = transactionType === 'recipient' ? '+$' : '$' ;
            console.log('transactionType ===>', transactionType);
          
            console.log('loginEmail ==>', loginEmail, 'Transaction =>', transaction);

            const formattedTransaction = {
                transactionType,
                key: transaction._id,
                recipient: recipientField,
                memo: transaction.memo_at_txn,
                transactionDate: `${date}`,
                amount: transactionPrefix + transactionAmount,
                transactionFee: transaction.blockchain_fees,
                status: transaction.txn_status,
                transactionId: transaction.exchange_txn_id,
            }

            if(prevResult[formattedTransaction.transactionDate]) {
                prevResult[formattedTransaction.transactionDate].push(formattedTransaction);
            } else {
                prevResult[formattedTransaction.transactionDate] = [formattedTransaction];
            }

            return prevResult;
            
        }, {});
        
        setGroupedTransactionsData(groupedTransactions);

        setData(rows);
      }, [transactions]);

    return (
        <div>
        {isOnline ? (
            <Card className="transaction-card-body">
                {
                    Object.keys(groupedTransactionsData).map((groupedTransKey) => (
                        <div className="transaction-group" key={groupedTransKey}>
                            <p className="transaction-date">{groupedTransKey}</p>
                            {
                            groupedTransactionsData[groupedTransKey].map(transaction => (
                                    <Card 
                                        key={transaction.key} 
                                        className="transaction-card-item" 
                                        type="inner" 
                                        onClick={() => navigate(`/transactions/${transaction.transactionId}`)}
                                    >
                                        <div 
                                            className={`transaction-item-details ${transaction.transactionType === 'recipient' ? 'transaction-recipient' : ''}`}
                                        >
                                            <div>
                                                <div style={{fontSize: '16px'}}>
                                                    {transaction.recipient}
                                                </div>
                                                <div className="transaction-memo">
                                                    <i style={{fontSize: '13px'}}>{transaction.memo}</i>
                                                </div>
                                            </div>
                                            <div className="transaction-amount">{transaction.amount}</div>
                                        </div>
                                    </Card>
                            )) 
                            }
                        </div>
                    ))
                }
                {/* {
                    data.map(transaction => (
                        <div key={transaction.key}>
                            <p className="transaction-date">{transaction.transactionDate}</p>
                            <Card className="transaction-card-item" type="inner" onClick={handleClick}>
                                {transaction.recipient}
                            </Card>
                        </div>
                    ))
                } */}
            </Card>
            ) : (
                <p style={{ color: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>You are offline. Please check your connection.</p>
            )}
        </div>
      );
}
export default TransactionListDisplay;