
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams, Link } from "react-router-dom"
import { confirmUserEmail } from "../../firebase/firebase"

//This component confirms your email when you first authenticate via Firebase

const ConfirmEmail = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [confirmEmailSuccess, setConfirmEmailSuccess] = useState(false)

  // GK 2023-10-21 - oobCode = Out-of-Band Code = Multi-Factor Authentication code
  // This is bascially like when you click on the link sent to your email
  let oobCode = searchParams.get('oobCode')

  const processEmailConfirmation = async () => {
    try {
      await confirmUserEmail(oobCode);
      setConfirmEmailSuccess(true)
    } catch (error) {
      setConfirmEmailSuccess(false);
    }
  }
  
  // The hook is running twice when <React.StrictMode> is activated
  // causing the oobCode to be reused.
  // GK 11/2/2023 - We are currently not using strict mode. 
  useEffect(() => {
    if (oobCode) {
      processEmailConfirmation();
    }
  
  // cleanup function.
  return () => {
    oobCode = null
  }
  }, [])
  
  // Shows the HTML when you click on the "confirm email" sent to your email
  return (
    <div style={{display: 'flex', justifyContent: 'center', color: '#fff'}}>
      { confirmEmailSuccess && oobCode ?
        <div>
          <h3>Thank you for confirming your email!</h3>
          <Link className="text-link" to="/login">Go to Login Page</Link>
        </div>
        :
        <div>
          <h3>There was a problem confirming your email.</h3>
          <Link className="text-link" to="/login">Go to Login Page</Link>
        </div>
      }
    </div>
  )
}
export default ConfirmEmail
