import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Space} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

import './styles/Home.css';
import wholesaleDistributionCenter from '../images/wholesale-distribution-center.jpg';
import mailpersonWithPackage from '../images/mailperson-with-package.jpg';
import warehouse from '../images/warehouse.jpg';
import foodAndBev from '../images/food-and-beverage-import.jpg';
import offshoreWorkersNigeria from '../images/offshore-workers-nigeria-1.jpg';
import shippingJapanBeautiful from '../images/shipping-japan-beautiful.jpg';
import hawaiiBeach from '../images/hawaii-beach.jpg';
import walletWithLogo from '../images/wallet-with-logo-3.jpeg';
import homeworkOverseas from '../images/record-keeping.jpg';


const UseCases = () => {

    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(auth.loginDetails) {
          navigate('/transactions')
        }
      }, []);

    return (
        <div>
            <Row justify="center">
                <Col>
                    
                    {/* upgrade your wallet  */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Versatile and useful. </h2>
                                    
                            <p className="auth-title-text">
                                Punkypay is the <Link class="light-blue" to="/cost-breakdown">cheapest and fastest</Link> way of paying someone internatioanlly. 
                            </p>
                            <p className="auth-title-text">
                                How will Punkypay help you?
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={walletWithLogo} alt="walletOfFuture"/>
                        </div>
                    </div> {/* end container */}
                    {/* 
                        Upgrade your business. 
                        There are so many use cases for Punkypay that we can't mention every one of them.
                        How will Punkypay help your business?
                        MAYBE THROW IN A LINK TO SALES@PUNKYPAY.COM, AFTER I START SELLING THIS
                    */}

                    {/* wholesale distribution */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Paying Offshore Workers </h2>
                            <p className="auth-title-text">
                                International wire transfers and other traditional payment methods often come with hefty fees, unfavorable exchange rates, and take days to complete. Punkypay offers significantly lower transaction costs, much better exchange rates, and nearly instant payments. This means more of the funds you intend to send abroad actually reach your workers.
                            </p>

                            <p className="auth-title-text">
                                Let’s imagine your company is paying an offshore worker $10,000. 
                                If you were paying them via a credit card, about 7%, or $700, would be spent on transaction fees. 
                                If you were paying via bank wire, 4%, or $400, would be spent on transaction fees. 
                                If you were paying via Punkypay, about 0.1%, or $10, would be spent on transaction fees. 
                                This saves your company $690 that goes straight into your profit margin, no effort needed.
                            </p>
                            <p className="auth-title-text">
                                Not sure what these fees come from? <Link class="light-blue" to="/cost-breakdown">Click here</Link> to find out. 
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={offshoreWorkersNigeria} alt="offshore-workers-nigeria"/>
                        </div>
                    </div> {/* end container */}

                    {/* paying offshore workers */}
                    <div className="container">
                        <div className="text-container">
                        <h2 className="auth-title-text"> Wholesale Distribution </h2>
                            <p className="auth-title-text">
                                Wholesalers who import products in bulk can optimize their international transactions by using Punkypay. 
                                This results in cost savings up to 10%, and drastically improves your company’s bottom line. 
                            </p>

                            {/* <p className="auth-title-text">
                                Let’s imagine your company buys textiles from abroad and sells them domestically. 
                                If your company buys $1,000,000 of textiles and you have to pay 3% in fees to make this transaction, your company will spend $30,000. 
                                If your company used Punkypay to make the transaction, we would charge just 1%, or $10,000.  
                                This saves your company $20,000 that goes straight into your profit margin, no effort needed.
                            </p> */}
                        </div>
                        <div className="image-container">
                        <img src={wholesaleDistributionCenter} alt="wholesaleDistributionCenter"/>
                        </div>
                    </div> {/* end container */}

                    {/* Living or Studying Abroad */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Living or Studying Abroad </h2>
                            <p className="auth-title-text">
                                Dealing with the banking system while you're abroad can be slow, expensive, and <i>frustrating</i>. 
                                Trust us, we've been there. 
                            </p>
                            <p className="auth-title-text">
                                Punkypay was originally designed for people living internationally. 
                                Punkypay moves your money overseas in minutes, costs a tiny fraction of what you would otherwise pay, and delivers a much more reliable experience.   
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={homeworkOverseas} alt="homework-overseas"/>
                        </div>
                    </div> {/* end container */}

                    {/* Hotel and tour payments */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Hotel and Tour Payments </h2>
                            <p className="auth-title-text">
                                Travelers can use Punkypay to book and pay for hotels, tours, and activities at their destination. 
                                This eliminates the need to carry large amounts of cash or rely on alternative payment methods, streamlining the booking process.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={hawaiiBeach} alt="hawaii-beach"/>
                        </div>
                    </div> {/* end container */}

                    {/* e-commerce business expansion */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> E-Commerce Business Expansion </h2>
                            <p className="auth-title-text">
                                Suppose you run an e-commerce store and want to expand your product line by sourcing unique items from international suppliers. 
                                You can use Punkypay to pay your overseas suppliers quickly, securely, and at a fraction of the cost compared to traditional bank transfers.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={mailpersonWithPackage} alt="mailpersonWithPackage"/>
                        </div>
                    </div> {/* end container */}


                    {/* food and beverage import */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Food and Beverage Import </h2>
                            <p className="auth-title-text">
                                Businesses that import specialty food and beverages from abroad can benefit from the speed and cost savings of Punkypay, ensuring a more competitive pricing structure.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={foodAndBev} alt="food-and-beverage-import"/>
                        </div>
                    </div> {/* end container */}


                    {/* manufacturing components */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Manufacturing Components </h2>
                            <p className="auth-title-text">
                                If you're a manufacturer, you may need to import specific components or raw materials from overseas to keep your production running smoothly. 
                                Punkypay ensures that payments are processed rapidly and affordably, allowing you to maintain a competitive edge.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={warehouse} alt="warehouse"/>
                        </div>
                    </div> {/* end container */}




                    {/* dropshipping */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Dropshipping </h2>
                            <p className="auth-title-text">
                                If you're involved in dropshipping, where you source products from international suppliers and sell them online without holding inventory, Punkypay can facilitate faster and more cost-effective payments to your suppliers.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={shippingJapanBeautiful} alt="shipping-japan-beautiful"/>
                        </div>
                    </div> {/* end container */}

                </Col>
            </Row>
        </div>
    )
}

export default UseCases;



// I think I can delete below but want to double check before I do:

/* 

                    <h2 className="auth-title-text"> <b> International Payments. Way cheaper. Way faster. </b> </h2>
                    
                    <h1 className="auth-title-text">
                        Tired of international transaction fees eating to your profit margin? 
                        Punkypay will radically improve your bottom line. 
                        International transactions on Punkypay settle for 1%. This is way cheaper than international wire transfers which settle for over 3%, and international credit card transactions which settle for about 7%. 
                        
                    </h1>
                    <h1 className="auth-title-text">
                        All transactions settle in about 3 minutes. International bank wires settle in about 2 days and international credit card transactions settle in about 5 days. Most importantly, Punkypay will never hold your money in limbo. 
                    </h1>
                    <h1 className="auth-title-text">
                        If you want to see how much Punkypay will save you, <Link to="/how-much-punkypay-saves-you">click here. </Link>
                    </h1>
                    <p className="auth-title-text">
                        <i> EARLY BIRD SPECIAL: Punkypay is charging no transaction fees until further notice. </i>
                    </p>


                    <div className="img-home-wrapper">
                        <img className="img-home" src={img} alt="Home Image"/>
                    </div>

                    <h2 className="auth-title-text"> <b> Useful, not Theoretical </b> </h2>
                    <h1 className="auth-title-text">
                        With Punkypay, you use the US Dollar in cryptocurrency format. 
                        By using the US Dollar in crypto format, you can get your money to your destination 99% faster than any other 
                        payment method for about 85% less. Each transaction settles in about 3 minutes and costs 1% of the total amount sent. 
                        Once your money reaches its destination, the recipient can easily convert their US Dollars into local currency. 
                    </h1>
                    <p className="auth-title-text">
                        <i> EARLY BIRD SPECIAL: Punkypay is charging no transaction fees until further notice </i>
                    </p>


                    <div className="img-home-wrapper">
                        <img className="img-home" src={midevalCoinExchange} alt="wildWestManWithHammer"/>
                    </div>

                    <h2 className="auth-title-text"> <b>  Intuitive interface </b> </h2>
                    <h1 className="auth-title-text">
                        Cryptocurrencies have been plagued by poor user interface since their inception. 
                        Punkypay makes paying with crypto easy. 
                        Instead of sending money to someone’s hexidecimal crypto address, you simply send money to their email. 
                    </h1>
                    

                    <div className="img-home-wrapper">
                        <img className="img-home" src={baroqueWomanWithBitcoin} alt="wildWestManWithHammer"/>
                    </div>

                    <h2 className="auth-title-text"> <b> Punkypay in Action </b> </h2>
                    <h3 className="auth-title-text"> Wholesale Distribution </h3>
                    <p className="auth-title-text">
                        Wholesalers who import products in bulk can optimize their international transactions by using PunkyPay. This results in cost savings up to 10%, and drastically improves your company’s bottom line. 
                    </p>

                    <p className="auth-title-text">
                        Let’s imagine your company buys textiles from abroad and sells them domestically. 
                        If your company buys $1,000,000 of textiles and you have to pay 3% in fees to make this transaction, your company will spend $30,000. 
                        If your company used Punkypay to make the transaction, we would charge just 1%, or $10,000.  
                        This saves your company $20,000 that goes straight into your profit margin, no effort needed.
                    </p>

                    <h3 className="auth-title-text"> E-Commerce Business Expansion </h3>
                    <p className="auth-title-text">
                        Suppose you run an e-commerce store and want to expand your product line by sourcing unique items from international suppliers. 
                        You can use PunkyPay to pay your overseas suppliers quickly, securely, and at a fraction of the cost compared to traditional bank transfers.
                    </p>

                    <h3 className="auth-title-text"> Manufacturing Components </h3>
                    <p className="auth-title-text">
                        If you're a manufacturer, you may need to import specific components or raw materials from overseas to keep your production running smoothly. 
                        PunkyPay ensures that payments are processed rapidly and affordably, allowing you to maintain a competitive edge.
                    </p>

                    <h3 className="auth-title-text"> Food and Beverage Import </h3>
                    <p className="auth-title-text"> 
                        Businesses that import specialty food and beverages from abroad can benefit from the speed and cost savings of PunkyPay, ensuring a more competitive pricing structure.
                    </p>

                    <h3 className="auth-title-text"> Paying Offshore Workers </h3>
                    <p className="auth-title-text">
                        International wire transfers and other traditional payment methods often come with hefty fees, unfavorable exchange rates, and take days to complete. 
                        PunkyPay offers significantly lower transaction costs, much better exchange rates, and nearly instant payments. 
                        This means more of the funds you intend to send abroad actually reach your workers.
                    </p>
                    
                    <h3 className="auth-title-text"> Dropshipping </h3>
                    <p className="auth-title-text">
                    If you're involved in dropshipping, where you source products from international suppliers and sell them online without holding inventory, PunkyPay can facilitate faster and more cost-effective payments to your suppliers.
                    </p>

                    <h3 className="auth-title-text"> Simplified Record Keeping </h3>
                    <p className="auth-title-text">
                        Merchants and Customers can attach notes and receipts to each transaction, helping both parties keep clear records of the purpose of payments and facilitating financial record-keeping for tax or accounting purposes.
                    </p>

                    <h3 className="auth-title-text"> Currency Conversion </h3>
                    <p className="auth-title-text">
                        If you are paying people in different countries with various currencies, the Punkypay network always provides the most competitive currency conversion rates. 
                        This results in significant cost savings for the merchant and customer.
                    </p>

                    <h3 className="auth-title-text"> Hotel and Tour Payments</h3>
                    <p className="auth-title-text"> 
                        Travelers can use PunkyPay to book and pay for hotels, tours, and activities at their destination. 
                        This eliminates the need to carry large amounts of cash or rely on alternative payment methods, streamlining the booking process.
                    </p>

                    <h2 className="auth-title-text"> Why We Created Punkypay </h2>

                    <p className="auth-title-text"> 
                        Punkypay was dreamed up by Gavin Kerr. 
                        Gavin has both conducted business abroad and lived overseas, so he understands the complexity, the cost, and the slowness of making international transactions. 
                        Gavin has worked as a Data Scientist at TD Bank, focusing on compliance. 
                        Gavin has volunteered his time to build Punkypay alongside numerous other programmers - hailing from Japan, India, Nigeria, and Indonesia. 
                    </p>

                    <p className="auth-title-text">
                        By making payments affordable, easy, and fast we know that we can help bridge borders, making the world a better place. 
                    </p>

                    <div className="img-home-wrapper">
                        <img className="img-home" src={japanBeautifulAnime} alt="japanBeautifulAnime"/>
                    </div>
*/