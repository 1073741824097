import axios from 'axios';
import { getAddresses } from "./crypto-api/coinbase";

// GK 2023-10-22 - stores crypto account in our mongo database 
export const storeCryptoAccountInMongo = async (
  // login_email is taken from the backend
  address,
  exchange,
  user,
  wallet
) => {
  // var myHeaders = new Headers();
  // myHeaders.append("Content-Type", "application/json");
  console.log('storeCryptoAccountInMongo Address ===>', address)
  const account = {
    exchange: exchange, // "UserInput"
    // solusdc_address: address, 
    // GK June 2024 - will need to replace the above with eth usdc addres, or get the addresses twice (the solUSDC address and the ethUSDC address)
    // GK June 2024 - ethusdc_address: address, 
    ethusdc_address: address, 
    exchange_id: user?.id, // Create a Unique ID << we cant use the one automatically created by Mongo, because we will filter based on this field later
    crypto_exchange_status: user?.exchange_status, 
    exchange_email: user?.email, 
   // coinbase_ethusdc_id: wallet?.id,
    exchange_usdc_id: wallet?.id, 
    usdc_balance: wallet?.balance,  
    // coinbase_ethusdc_address: address, 
    created_at: new Date(), //date created
    last_login: new Date(), //cipy created_at field, ultimately this is more important for Exchanges rather than UserInput
  };

  return axios.post('/api/crypto-account', account);
};

// GK 2023-10-22 - endpoints from the backend, can search for "api/crypto-account" or "otp/confirm"
// check server files index.js and routes/cryptoAccount.js
export const confirmCryptoAccountOTP = async (otp) => {
  return axios.post('/api/crypto-account/otp/confirm', {otp});
};

export const resendCryptoAccountOTP = async () => {
  return axios.post('/api/crypto-account/otp/resend');
};
