import React, { useEffect, useState } from "react";
import { Outlet, useLocation, Link, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Button, message, Layout, Menu, ConfigProvider, theme, Space } from "antd";
import { 
  UserOutlined, 
  HomeOutlined, 
  LogoutOutlined,
  LoginOutlined, 
  TransactionOutlined, 
  BookOutlined,
  ShopOutlined,
  CarOutlined,
  RocketOutlined,
  MedicineBoxOutlined,
  SoundOutlined, 
  DollarOutlined,
  EyeOutlined,
  BankOutlined,
  QuestionOutlined,


} from '@ant-design/icons';

import Sidebar from './Components/Sidebar/Sidebar';
import { useAuth } from './contexts/auth-context';
import { useAppBreakpoint } from './hooks/breakpoints';
import { LAST_VISITED_LOCATION } from "./const/const";
import { registerAuthorizationHeader } from "./environment";
import appLogo from './app-logo.svg';
import { PUBLIC_URLS } from "./routes/router";
import "./App.css";

const { Header, Content, Footer } = Layout;
const { defaultAlgorithm, darkAlgorithm } = theme;

const MAX_ITEMS_BEFORE_NAV_ELLIPSE_DISPLAY = 6;

const PUBLIC_SIDEBAR_ITEMS = [
  {
    key: '1',
    icon: React.createElement(HomeOutlined),
    label: 'Home',
    link: '/'
  },
  {
    key: '2',
    icon: React.createElement(LoginOutlined),
    label: 'Login',
    link: '/login'
  },
  {
    key: '3',
    icon: React.createElement(UserOutlined),
    label: 'Register',
    link: '/register'
  }
];

const PUBLIC_NAV_ITEMS = [
  {
    key: '1', 
    label: 'Home', 
    link: '/',
    icon: React.createElement(HomeOutlined)
  },
  {
    key: String(PUBLIC_SIDEBAR_ITEMS.length + 2), 
    label: 'Use Cases', 
    link: '/use-cases',
    icon: React.createElement(ShopOutlined)
  },
  {
    key: String(PUBLIC_SIDEBAR_ITEMS.length + 3), 
    label: 'Costs', 
    link: '/cost-breakdown',
    icon: React.createElement(HomeOutlined)
  },
  {
    key: String(PUBLIC_SIDEBAR_ITEMS.length + 4), 
    label: 'Roadmap', 
    link: '/roadmap',
    icon: React.createElement(RocketOutlined)
  },
  { /* have not made this page yet */
    key: String(PUBLIC_SIDEBAR_ITEMS.length + 5), 
    label: 'Podcast', 
    link: '/podcast',
    icon: React.createElement(SoundOutlined)
  },
];

// adding public nav items to public mobile sidebar items
PUBLIC_NAV_ITEMS.forEach((navItem) => {
  if(navItem.label !== "Home") {
    PUBLIC_SIDEBAR_ITEMS.push(navItem);
  }
})

const PROTECTED_SIDEBAR_ITEMS = [
  {
    key: '1',
    icon: React.createElement(EyeOutlined),
    label: 'View Payments', 
    link: '/transactions'
  },
  {
    key: '2',
    icon: React.createElement(DollarOutlined),
    label: 'Pay Now', 
    link: '/transaction'
  },
  {
    key: '3',
    icon: React.createElement(BankOutlined),
    label: 'Sign into Exchange', 
    link: '/crypto-login'
  },
  {
    key: '4',
    icon: React.createElement(QuestionOutlined),
    label: 'Support', 
    link: '/support'
  }
];


const PROTECTED_NAV_ITEMS = [];

// adding protected nav items to protected mobile sidebar items
PROTECTED_SIDEBAR_ITEMS.forEach((navItem) => {
  PROTECTED_NAV_ITEMS.push(navItem);
})

const LOGOUT_ITEM = {
  key: String(PROTECTED_SIDEBAR_ITEMS.length + 1),
  icon: React.createElement(LogoutOutlined),
  label: 'Logout',
  link: '/'
}

// GK 2023-11-02 - the wrapper to all of the components
function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const auth = useAuth();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { token: {colorBgContainer} } = theme.useToken();
  const sideBarItems = auth.loginDetails ? PROTECTED_SIDEBAR_ITEMS : PUBLIC_SIDEBAR_ITEMS;
  const navBarItems = auth.loginDetails ? PROTECTED_NAV_ITEMS : PUBLIC_NAV_ITEMS
  const isLargeScreen = useAppBreakpoint('lg');
  const storage = localStorage;

  useEffect(() => {// if user navigates by www subdomain, redirect to app main domain without www.
    const href = window.location.href;
    const subdomain = window.location.hostname.split('.')[0];
    if(subdomain.toLowerCase() === 'www') {
      const searchText = 'www.';
      const searchRegEx = new RegExp(searchText, 'i');
      const newHref = href.replace(searchRegEx, ''); // strip off "www." from the domain url

      window.location.href = newHref;
    }
  }, []);

  useEffect(() => {
    //if the url is public or not logged in, don't monitor location. Just return the function
    if(PUBLIC_URLS.includes(location.pathname) || !auth.loginDetails) { 
      return;
    }
    // otherwise, set the "location" to where the user was last
    storage.setItem(LAST_VISITED_LOCATION, location.pathname);

  }, [location])

  // 2023-10-24 GK - Authorization Header for making calls to the API
  useEffect(() => {
    registerAuthorizationHeader(auth.loginDetails?.accessToken);
  }, []);

  const handleLogout = () => {
    auth.signOut();
    if(!isLargeScreen) {
      setIsSidebarOpen(false);
    }
  };

  // mobile sidebar
  const handleSideBarNav = (param) => {
    if(auth.loginDetails && param.key === LOGOUT_ITEM.key) {
      handleLogout();
      return;
    }
    
    const foundItem = sideBarItems.find(item => item.key === param.key);
    if(!foundItem || !foundItem.link) {
      return;
    }

    navigate(foundItem.link);
    setIsSidebarOpen(false);
  }

  // menu navbar
  const handleNavBar = (param) => {    
    const foundItem = navBarItems.find(item => item.key === param.key);
    if(!foundItem || !foundItem.link) {
      return;
    }

    navigate(foundItem.link);
  }

  // Get the side bar key for the current location and use it to highlight the hamburger menu
  const getDefaultKey = () => {
    return sideBarItems.find(item => item.link === location.pathname)?.key;
  }

  if(auth.authenticating) {
    return <div style={{textAlign: 'center', margin: 8}}>Loading...</div>
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
        // token: {
        //   fontFamily: 'YogaSansProRegular'
        // }
      }}
    >
      <Layout className="app-layout">
          <Sidebar  
            isOpen={isSidebarOpen} 
            onClose={() => setIsSidebarOpen(false)} 
            overlay={true}
          >
            <Menu
              defaultSelectedKeys={[getDefaultKey()]}
              selectedKeys={[getDefaultKey()]}
              theme="dark"
              mode="inline"
              items={ auth.loginDetails ? 
                [...PROTECTED_SIDEBAR_ITEMS, LOGOUT_ITEM] : 
                PUBLIC_SIDEBAR_ITEMS 
              }
              onClick={handleSideBarNav}
            />
          </Sidebar>
        <Layout>

          <Header className="app-layout-header">
            <div className="app-layout-header__left">
              {
                !isLargeScreen && (
                  <Button
                      className="btn-hamburger"
                      type="primary"
                      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      style={{
                        
                      }}
                    >
                      <div className="hamburger__line"></div>
                      <div className="hamburger__line"></div>
                      <div className="hamburger__line"></div>
                    </Button>
                )
              }
              <div className="app-layout-header__title" >
                <Link className="app-layout-header__title--link" to="/"><img src={appLogo} /></Link>
                {
                  isLargeScreen && !auth.loginDetails && (
                    <Menu
                      theme="dark"
                      mode="horizontal"
                      defaultSelectedKeys={[getDefaultKey()]}
                      selectedKeys={[getDefaultKey()]}
                      items={PUBLIC_NAV_ITEMS.map(({icon, ...restProps}) => ({...restProps}))}
                      disabledOverflow={ PUBLIC_NAV_ITEMS.length < MAX_ITEMS_BEFORE_NAV_ELLIPSE_DISPLAY }
                      style={{
                        flex: 1,
                        minWidth: 0,
                        marginLeft: '10px'
                      }}
                      onClick={handleNavBar}
                    />
                  )
                }
              </div>
            </div>
            { 
              isLargeScreen && auth.loginDetails && (<Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[getDefaultKey()]}
                selectedKeys={[getDefaultKey()]}
                items={PROTECTED_NAV_ITEMS.map(({icon, ...restProps}) => ({...restProps}))}
                disabledOverflow={ PROTECTED_NAV_ITEMS.length < MAX_ITEMS_BEFORE_NAV_ELLIPSE_DISPLAY }
                style={{
                  flex: 1,
                  minWidth: 0,
                  marginLeft: '10px'
                }}
                onClick={handleNavBar}
                />
              )
            }
            {
              isLargeScreen && (
                <div className="app-layout-header__right">
                  {
                    auth.loginDetails && (
                      <Button type="primary" onClick={handleLogout} style={{ margin: 4 }}>
                        Log out
                      </Button>
                    )
                  }
                  {
                    !auth.loginDetails && (
                      <Space>
                        <Button type="primary" onClick={() => navigate('/login')} style={{ margin: 4 }}>
                          Login
                        </Button>
                        <Button type="primary" onClick={() => navigate('/register')} style={{ margin: 4 }}>
                          Register
                        </Button>
                      </Space>
                    )
                  }
                </div>
              )
            }
          </Header>
          <Content className="app-layout-content">
            <Outlet/>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Punkypay Incorporated ©2024. For questions, email <a class="light-blue" href="mailto:support@punkypay.com">support@punkypay.com</a></Footer>
        </Layout>
      </Layout>
      <ToastContainer/>
    </ConfigProvider>
  )
}

export default App;
