
  // Simple landing page for the Firebase user actions

  const AuthActionsLandingPage = () => {
    return (
      <div>
        <h3>Loading...</h3>
      </div>
    )
  }
  export default AuthActionsLandingPage;
  