const { Navigate } = require("react-router-dom");
const { useAuth } = require("../../contexts/auth-context")

//GK 2023-10-22 - Defines the RequireAuth component 
const RequireAuth = ({children}) => {
    const auth = useAuth();

    // if user is logged in, render the component the user is trying to view or redirect to login page
    return !auth.loginDetails ? <Navigate to="/" replace/> : children;
}

export default RequireAuth;