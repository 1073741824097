import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Space, Table} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

import costSavings from '../images/cost-savings.jpeg';
import './styles/Home.css';

const data1000 = [
    {
      key: '1',
      method: 'Bank Wire',
      cost: "$155",
      time: '2 days',
    },
    {
      key: '2',
      method: 'Credit Card',
      cost: "$70",
      time: '5 days',
    },
    {
        key: '1',
        method: 'Punkypay',
        cost: '$2',
        time: "3 minutes"
    }
  ];

  const cols1000 = [
    {
      title: 'Method of Payment',
      dataIndex: 'method',
      key: 'method',
    },
    {
      title: 'Cost of spending $1,000 abroad',
      dataIndex: 'cost',
      key: 'cost',
    },
    {
      title: 'Speed of moving $1,000 abroad',
      dataIndex: 'time',
      key: 'time',
    },
  ];
  
const dataTenK = [
    {
      key: '1',
      method: 'Bank Wire',
      cost: "$3,125",
      time: '2 days',
    },
    {
      key: '2',
      method: 'Credit Card',
      cost: "$7,000",
      time: '5 days',
    },
    {
        key: '1',
        method: 'Punkypay',
        cost: '$11',
        time: "3 minutes"
    }
  ];

  const colsTenK = [
    {
      title: 'Method of Payment',
      dataIndex: 'method',
      key: 'method',
    },
    {
      title: 'Cost of spending $10,000 abroad',
      dataIndex: 'cost',
      key: 'cost',
    },
    {
      title: 'Speed of moving $10,000 abroad',
      dataIndex: 'time',
      key: 'time',
    },
  ];



const CostBreakdown = () => {

    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(auth.loginDetails) {
          navigate('/transactions')
        }
      }, []);
      
    return (
        <div>
            <Row justify="center">
              <Col>
                
                <h2 className="auth-title-text"> How much Punkypay Saves You </h2>
                <br></br>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={costSavings} class="half-size-full-screen" alt="cost-savings"/>
                </div>
                <br></br>
                <h1 className="auth-title-text"> 
                    Until recently, sending money to other countries was slow and expensive. 
                    It was a complicated process with lots of waiting and high fees. 
                    But things have changed. 
                    Thanks to Punkypay, sending money across borders is finally cheap and fast. 
                    First, we will discuss the fees associated with international payments. 
                    Second, we will discuss the speed of international payments. 
                    Finally, we will compare the cost and speed of spending $1,000 and $100,000 between Bank Wire, Credit Card, and Punkypay. 
                </h1>
                <h1 className="auth-title-text"> 
                    Whether you’re paying by credit card, bank wire, or a currency exchange you are always paying fees. 
                    These fees are typically split between the merchant and the customer, and oftentimes you won’t even realize that you’re paying these fees. 
                </h1>
                <h1 className="auth-title-text">
                    Here’s a list of fees that may be applied to an international transaction:
                </h1>
                <br></br>
                <ul className="auth-title-text">
                    <h1 className="auth-title-text">- International banking fees</h1>
                    <h1 className="auth-title-text">- Exchange rate fees</h1>
                    <h1 className="auth-title-text">- Interchange fees</h1>
                    <h1 className="auth-title-text">- Processor fees</h1>
                    <h1 className="auth-title-text">- Assessment fees</h1>
                    <h1 className="auth-title-text">- Outgoing wire fees</h1>
                    <h1 className="auth-title-text">- Incoming wire fees</h1>
                    <h1 className="auth-title-text">- Etc. </h1>
                </ul>
                <h1 className="auth-title-text">
                    Punkypay is more streamlined than the traditional banking system, making your payments cost less. And, we're faster.
                </h1>
                <h1 className="auth-title-text"> 
                    Sending money in the Traditional Banking system is slow for two reasons: 
                </h1>
                <h1 className="auth-title-text">1) Structural issues in the banking system </h1>
                <h1 className="auth-title-text">2) Banks can make money on the "<a class="light-blue" href="https://sbo.financial/blog/wealth-creation/the-power-of-float/#:~:text=Businesses%20can%20profit%20on%20this,financial%20institution%20known%20to%20man." target="_blank">float</a>" while your money is in transit to its destination </h1>
                <h1 className="auth-title-text">
                    The tables below compare the costs and speed of using Credit Card, Bank Wire, and Punkypay for a $1,000 and $10,000 purchase from abroad:
                </h1>

                <p className="auth-title-text">
                    <p> Currently, Punkypay is charging no fees. In the future when we do start charging fees, we can guarantee we will still be way cheaper than other payment methods. :) </p>
                </p>

                <h1 className="auth-title-text">
                    <b>Approximate Cost and Speed of spending $1,000 abroad</b>
                </h1>
                <Table dataSource={data1000} columns={cols1000} pagination={false}/>;
                <p className="auth-title-text"><i>
                    ** Bank Wire fees include “exchange rate fees”, incoming wire fees, and outgoing wire fees. 
                    Credit card fees include “exchange rate fees”, interchange fees, processor fees, assessment fees. 
                    We excluded International Banking Fees because often people who regularly make international purchases will buy a credit card (such as the American Express Gold Card) which does not levy these fees. 
                    Punkypay’s fees include transaction fees levied by the company of Punkypay, transaction fees levied by the blockchain, and approximate fees levied by the exchange to convert USD Coin into Local Currency. 
                </i></p>


                <h1 className="auth-title-text">
                    <b>Approximate Cost and Speed of spending $10,000 abroad</b>
                </h1>
                <Table dataSource={dataTenK} columns={colsTenK} pagination={false}/>
                <p className="auth-title-text"><i>
                    ** Bank Wire fees include “exchange rate fees”, incoming wire fees, and outgoing wire fees. 
                    Credit card fees include “exchange rate fees”, interchange fees, processor fees, assessment fees. 
                    We excluded International Banking Fees because often people who regularly make international purchases will buy a credit card (such as the American Express Gold Card) which does not levy these fees. 
                    Punkypay’s fees include transaction fees levied by the company of Punkypay, transaction fees levied by the blockchain, and approximate fees levied by the exchange to convert USD Coin into Local Currency. 
                </i></p>

                <h1 className="auth-title-text"> As you can see in the tables above, Punkypay is clearly the cheapest and fastest form of payment. 
                    <Link class="light-blue" to='/register' > Sign up</Link> for an account now. 
                </h1>
                </Col>
            </Row>
        </div>
    )
}

export default CostBreakdown;