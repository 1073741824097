import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Space} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

import './styles/Home.css';
import usefulNowAlwaysGrowing from '../images/useful-now-always-growing.jpg';
import NoLimits from '../images/no-limits.jpg';
import improvedAdvertising from '../images/improved-ads.jpg';
import chatUpAStorm from '../images/chat-up-a-storm-6.jpg';
import decentralizedSecurity from '../images/decentralized-security-image.jpg';
import integrateWithWorld from '../images/integrate-with-world.jpeg';


const Support = () => {

    const auth = useAuth();
    const navigate = useNavigate();

    // useEffect(() => {
    //     if(auth.loginDetails) {
    //       navigate('/transactions')
    //     }
    //   }, []);

    return (
        
        <div>
            <Row justify="center">
                <Col>
                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Useful now, always growing </h2>
                                    
                            <p className="auth-title-text">
                                Punkypay is currently the cheapest and fastest way of paying someone internationally, and we’re constantly innovating to become the best payment method all-around. 
                            </p>
                            <p>
                                Our future is bright. To give you a preview of what's coming, take a look at the highlights from our Roadmap:
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={usefulNowAlwaysGrowing} alt="useful-now-always-growing"/>
                        </div>
                    </div> {/* end container */}
                    {/* 
                        Upgrade your business. 
                        There are so many use cases for Punkypay that we can't mention every one of them.
                        How will Punkypay help your business?
                        MAYBE THROW IN A LINK TO SALES@PUNKYPAY.COM, AFTER I START SELLING THIS
                    */}

                    {/* wholesale distribution */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> No limits </h2>
                            <p className="auth-title-text">
                                Presently, we are limited to payments under $10,000 per day. We will soon expand this to an infinite amount. 
                            </p>

                            <p className="auth-title-text">
                                Allowing larger amounts of money to be sent at once will allow large importers and exporters to use Punkypay. 
                                Ultimately, the huge cost-savings provided by Punkypay will trickle down to customers - making regular purchases cheaper. 
                                {/* ??? */}
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={NoLimits} alt="no-limits"/>
                        </div>
                    </div> {/* end container */}

                    {/* paying offshore workers */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Improved advertising, absolutely free </h2>
                            <p className="auth-title-text">

                                Timing is critical. 
                                As Punkypay is a payment platform, we are going to integrate advertisements into a catalog of endless scrolling, similar to Instagram, Facebook, or TikTok. 
                            </p>
                            <p className="auth-title-text">
                                But there is one key difference between advertising on Punkypay and other social media platforms: 
                                on Punkypay, you're using the platform to buy things. On social media, you're using the platform for entertainment.
                            </p>
                            <p className="auth-title-text">
                                We believe that this difference will make a user of Punkypay more receptive towards advertisements, so your company's advertising dollars will go farther.
                            </p>
                        </div>
                        <div className="image-container">
                        <img src={improvedAdvertising} alt="improved-advertising"/>
                        </div>
                    </div> {/* end container */}

                    {/* e-commerce business expansion */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Chat up a storm. </h2>
                            <p className="auth-title-text">
                                Deals take a long time. The language barrier is real. 
                                Punkypay Chat is going to simplify this process. 
                            </p>
                            <p className="auth-title-text">
                                Rather than requiring customers to contact a merchant by email, whatsapp, or whatever - Punkypay Chat will allow you to message merchants directly through our platform. 
                            </p>
                            <p className="auth-title-text">
                                This reduces friction when making a sale. 
                            </p>
                            <p className="auth-title-text">
                                Punkypay will also include translation services inside of Punkypay Chat, so you can buy things without worrying about the language barrier. 
                            </p>

                        </div>
                        <div className="image-container">
                            <img src={chatUpAStorm} alt="chat-up-a-storm"/>
                        </div>
                    </div> {/* end container */}


                    {/* food and beverage import */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Integrate with your world </h2>
                            <p className="auth-title-text">
                                Whether you currently make sales entirely online or with a POS system, Punkypay will seamlessly integrate with your current payment platform. 
                            </p>
                            <p className='auth-title-text'>
                                Our goal is to make integrating Punkypay as easy as possible, because both customers and merchants will benefit from our platform. 
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={integrateWithWorld} alt="integrate-with-world"/>
                        </div>
                    </div> {/* end container */}


                    {/* manufacturing components */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Decentralized security </h2>
                            <p className="auth-title-text">
                                People think security and convenience are a trade-off, but it doesn’t have to be that way. 
                            </p>
                            <p className="auth-title-text">
                                We developed a higher standard of security to lock down your money while making the checkout process simpler. 
                                This technique has been dubbed “decentralized security”.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={decentralizedSecurity} alt="decentralied-security"/>
                        </div>
                    </div> {/* end container */}

                </Col>
            </Row>
        </div>
    )
}

export default Support;


