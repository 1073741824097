import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
  message,
  Alert
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {toast} from 'react-toastify';
import { Coinbase_Auth_Token } from "../../const/const";
import CryptoAccountLoginStatus from "../CryptoAccountLoginStatus";
import { getAccountData } from "../../rest/crypto-api/coinbase";
import { useAuth } from "../../contexts/auth-context";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};


// GK 10/22/2023 - Creates the Transaction submission form
// We should add in a network as a variable. So 'solana' or 'ethereum'
const Transaction = () => {
  const storage = localStorage;
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage(); //This comes from ANT Design, we are using this to help display error messages
  const [account, setAccount] = useState([]);
  const [twoFactorRequire, setTwoFactorRequire] = useState(false);
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const coinbaseUserAuthData = useSelector((state) => state.coinbase.coinbaseUserAuth.data?.oauth_meta);
  const cryptoAccount = useSelector(state => state.cryptoAccount.account);
  const auth = useAuth();
  const loginEmail = auth.currentUser?.email;
  const isUserInput = cryptoAccount.data?.exchange?.toLowerCase() === 'userinput';
  console.log('coinbaseUserAuth =>', coinbaseUserAuthData);
  let toastId = null;

  // GK 2023-10-22 - This shows errors via Toast, which we arent using anymore
  const showError = (message) => {
    if(!toast.isActive(toastId)) {
      toastId = toast.error(message, {
        onClose(){
          toastId = null;
        }
      });
    }
  };

  // GK 2023-10-22 - This fetches an account via Axios
  const fetchAccount = async () => {
    try {
      const coinbaseAuthToken = storage.getItem(Coinbase_Auth_Token);
      const response = await getAccountData(coinbaseAuthToken, 'USDC');
      if(response) {
        setAccount(response);
      } else {
        setError('Unable to get USDC account information from Coinbase. Please try again.');
      }

    } catch (error) {
      setError('Unable to get USDC account information from Coinbase. Please try again.');
    }
  }

  // GK 2023-10-22 - This fetches the account when the component renders
  useEffect(() => {
    fetchAccount();
  }, []);

  // GK 2023-10-22 - When the submit button is pressed, the send-crypto api call gets run with the supplied requestData
  const onFinish = async (value) => {
    if(!account) {
      return;
    }
    if(!loginEmail) { // This means the error is our customized error from the server
      setError('Can\'t fetch the user login email. Please login again to perform this operation.');
      return;
    }
    
    setLoading(true);
    try {
      const requestData = {
        accountId: account.id,
        accessToken: storage.getItem(Coinbase_Auth_Token),
        to: value.recipient.toLowerCase().trim(),
        from: loginEmail,
        amount: value.amount,
        currency: "USDC",
        network_name: 'ethereum', // This should be different based on the variable we put in at the top of this function
        shortMemo: value.shortMemo,
        notes: value.notes,
        twoFactorToken: twoFactorRequire ? value?.twoFactorToken : null,
      };
      const response = await axios.post('/api/transactions/send-crypto', requestData);
      if(response) {
        console.log('Transaction Created successfully =>', response.data);
        navigate("/transactions");
        setLoading(false);
      }
    } catch (error) {
      console.log('Transaction Create: Error =>', error);
      const errorData = error.response.data;
      setLoading(false);
      if(errorData.errorCode) { // This means the error is our customized error from the server
        setError(errorData.message);
        return;
      }
      console.log('Transaction Create: 2FA Auth required =>', errorData);
      let errorString = "";
      // GK 2023-10-22 - the errorData is an array coming from coinbase, we are looking for the "id" key
      errorData.errors?.forEach((err) => {
        if (err?.id === "two_factor_required") {
          setTwoFactorRequire(true);
          errorString += 'Please enter two-factor authentication token for Coinbase. You will recieve the token on an authenticator app (like Google Authy), text message, email, or security prompt.'
        } else {
          errorString += err?.message + "\n";
        }
      });
      setError(errorString);
    }
  }

  // GK 2023-10-22 - below is the HTML for the transaction component
  return (
    <div style={{ marginTop: "10px" }}>
      <Title className="text-center" level={3}>Transaction</Title>
      <div className="text-center" style={{marginBottom: '10px'}}>
        <CryptoAccountLoginStatus/>
      </div>
      {
        coinbaseUserAuthData && !isUserInput && (
          <div className="text-center" style={{marginBottom: '10px'}}>
            <Text
              style={{marginBottom: '5px'}} 
              type="warning"
            >
              You can only send {
              coinbaseUserAuthData.send_limit_amount
              } {
              coinbaseUserAuthData.send_limit_currency
              } per {
                coinbaseUserAuthData.send_limit_period
              } based on your coinbase settings
            </Text>
          </div>
        )
      }
      <Form
        labelCol={{
          xs: {span: 24},
          md: {span: 8}
        }}
        wrapperCol={{
          xs: {span: 24},
          md: {span: 8},
        }}
        form={form}
        name="transaction"
        onFinish={onFinish}
        validateMessages={validateMessages}
        // style={{ maxWidth: 800, margin: "auto" }}
      >
        <Form.Item
              name={"recipient"}
              label={"Recipient"}
              rules={[{ required: true }]}
              className="form-item"
            >
            <Input placeholder={"Recipient"} maxLength={225} disabled={twoFactorRequire}/>
        </Form.Item>
        <Form.Item
              name={"amount"}
              label={"Amount (USDC)"}
              rules={[{ required: true }]}
              className="form-item"
            >
            <InputNumber
              placeholder={"Amount"}
              style={{ width: "100%" }}
              min={0}
              disabled={twoFactorRequire}
            />
        </Form.Item>
        <Form.Item
              name={"shortMemo"}
              label={"Short Memo"}
              rules={[{ required: true }]}
              className="form-item"
            >
            <Input placeholder={"Short Memo"} maxLength={225} disabled={twoFactorRequire}/>
        </Form.Item>
        <Form.Item
              name={"notes"}
              label={"Notes"}
              rules={[{ required: false }]}
              className="form-item"
            >
            <TextArea disabled={twoFactorRequire} />
        </Form.Item>
        {twoFactorRequire && (
          <Form.Item
            name={"twoFactorToken"}
            label={"Two Factor Code"}
            rules={[{ required: true }]}
            className="form-item"
          >
            <Input placeholder={"Enter Two Factor Code"} maxLength={225} />
          </Form.Item>
        )}
        <Form.Item
          className="form-item form-item__submit"
          wrapperCol={{
            md: {
              offset: 8,
              span: 16,
            },
            xs: {
              span: 24,
            },
          }}
        >
          <Button type="primary" htmlType="submit" disabled={loading || isUserInput}>
              Submit
          </Button>
        </Form.Item>
        {/* <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            
          </Col>
        </Row> */}
        
        {/* <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" disabled={loading || isUserInput}>
              Submit
            </Button>
          </Col>
        </Row> */}
        {
              error && (
                <Row>
                  <Col
                    xs={{span: 24}}
                    md={{span: 16, offset: 4}}
                    style={{marginTop: "10px"}}
                  >
                    <Alert message={error} type="error" />
                  </Col>
                </Row>
              )
        }
        {
              isUserInput && (
                <Row>
                  <Col
                    xs={{span: 24}}
                    md={{span: 16, offset: 4}}
                    style={{marginTop: "10px"}}
                  >
                    <Alert message="Please sign in to Coinbase to make a transaction, you cannot make a transaction when you are using the Punkypay Mailroom." type="warning" />
                  </Col>
                </Row>
              )
        }
      </Form>

      <Row justify="center" gutter={[16, 14]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <p class="auth-title-text" justify="center"> Video showing <a class="light-blue" href="https://youtu.be/WaPv0scN3-c" target="_blank">how to make</a> a Transaction. (skip to 1:05)</p>
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 14]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <p class="auth-title-text" justify="center"> Video showing <a class="light-blue" href="https://youtu.be/cnFqZeTN-sM" target="_blank">how to get</a> USDC on Coinbase.</p>
        </Col>
      </Row>
    </div>
  );
};

export default Transaction;
