import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query/react";

//import session from "redux-persist/lib/storage/session"; // for sessionStorage
import storage from "redux-persist/lib/storage"; // for localStorage;
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

import coinbaseReducer from './reducers/coinbase.reducer';
import cryptoAccountReducer from "./reducers/cryptoAccount.reducer";

import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";

const persistConfig = {
  key: "root",
  storage,
};

// 2023-10-24 GK - combines reducers so you can pass it to the store
const reducer = combineReducers({
  coinbase: coinbaseReducer,
  cryptoAccount: cryptoAccountReducer
});

// 2023-10-24 GK - persists state after you refresh
const persistedReducer = persistReducer(persistConfig, reducer);

// 2023-10-24 GK - serializableCheck has to do with map, which we are not using here
// middleware is pre-set-up by reduxPersist
// https://redux-toolkit.js.org/api/getDefaultMiddleware
// https://redux-toolkit.js.org/api/configureStore
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// 2023-10-24 GK - sets up listeners for the reducers above
setupListeners(store.dispatch);

const persistor = persistStore(store);

const { dispatch } = store;

// 2023-10-24 GK - comes from redux
// https://redux.js.org/tutorials/fundamentals/part-2-concepts-data-flow
const useDispatch = () => useAppDispatch();

// 2023-10-24 GK - same here 
// https://redux.js.org/tutorials/fundamentals/part-2-concepts-data-flow
const useSelector = useAppSelector;

export { store, persistor, dispatch, useSelector, useDispatch };
