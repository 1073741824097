// This script allows axios to automatically append token Authorization header to every request going out.

import axios from 'axios';
import { AUTH_TOKEN } from "./const/const"

const storage = localStorage;
export const registerAuthorizationHeader = (tokenParam) => {
    const token = tokenParam || storage.getItem(AUTH_TOKEN);
    
    if(!token) {
        return false;
    }

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return true;
}
