// GK 2023/10/21 - this component helps the user Reset password and Verify Email via firebase
// https://firebase.google.com/docs/auth/custom-email-handler

import { Navigate, useSearchParams } from "react-router-dom";

const AuthActions = ({ children }) => {
  const [searchParams] = useSearchParams()

  // GK 2023/10/21 - gets OOBCode and Mode from  URL Search Params interface
  // https://developer.chrome.com/blog/urlsearchparams/#:~:text=The%20URLSearchParams%20API%20provides%20a,query%20parameters%20from%20the%20URL.
  let mode = searchParams.get('mode')
  let oobCode = searchParams.get('oobCode')

  if (mode === 'resetPassword') {
    const resetPasswordPath = `/confirm-password-reset?oobCode=${oobCode}`;

    return <Navigate to={resetPasswordPath} replace />
  } else if (mode === 'verifyEmail') {
    const confirmEmailPath = `/confirm-email?oobCode=${oobCode}`;

    return <Navigate to={confirmEmailPath} replace />
  }

  return children;
}
export default AuthActions;
