import { useState } from "react";
import { Button, Form, Input, message, Alert, Col, Row } from "antd";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { confirmPasswordReset } from "../../firebase/firebase";

// This component resets your password via Firebase

const ConfirmPasswordReset = ({ title }) => {
    const [messageApi, contextHolder] = message.useMessage();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMismatchMessage, setPasswordMismatchMessage] = useState('');
    let navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const [successMessage, setSuccessMessage] = useState(false)
    let oobCode = searchParams.get('oobCode')

  const onFinish = async () => {
    // GK 2023/10/21 - if you do not set your password and confirm password to the same thing, it shows an error
    if (password !== confirmPassword) {
        setPasswordMismatchMessage(true);
        return;
    }

      // GK 2023-10-22 - once you click on the reset password link you get sent to your email (which gives you the OOBCode)
      // you are then taken to a screen where you can set your new password
      try {
        if (oobCode) {
          await confirmPasswordReset(oobCode, confirmPassword);
          setPassword('');
          setConfirmPassword('');         
          setPasswordMismatchMessage(false);
          setSuccessMessage(true);
        } else {
          console.log('missing oobCode');
          messageApi.open({
            type: "error",
            content: "Somethng went wrong. try again!",
          });
        }
      } catch (error) {
        if (error.code === 'auth/invalid-action-code') {
            messageApi.open({
                type: "error",
                content: "Something went wrong. Try again!",
            });
        }
        console.log(error.message)        
      }
  }

  // GK 2023-10-22 - how does the errorInfo get supplied to this? 
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  
  // GK 2023-10-22 Creates the content for the component below
  return (
    <div style={{paddingTop: 20}}>
      {successMessage && (
        <Row>
            <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
                <Alert 
                    message="Password reset successfully" 
                    type="success" 
                />
            </Col>
        </Row>
        )
      }
      {passwordMismatchMessage && (
        <Row>
            <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
                <Alert 
                    message="Passwords do not match" 
                    type="error" 
                />
            </Col>
        </Row>
        )
      }
      <h2> Confirm Password Form</h2>
      <Form
        name="basic"
        labelCol={{
          xs: {span: 24},
          md: {span: 8}
        }}
        wrapperCol={{
          xs: {span: 24},
          md: {span: 8},
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          className="form-item"
          label="Enter the Password"
          name="password"
          value={password}
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>

        <Form.Item
          className="form-item"
          label="Confirm the Password"
          name="confirmPassword"
          value={confirmPassword}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
          ]}
        >
          <Input.Password onChange={(e) => setConfirmPassword(e.target.value)} />
        </Form.Item>

        <Form.Item
         className="form-item form-item__submit"
          wrapperCol={{
            md: {
              offset: 8,
              span: 16,
            },
            xs: {
              span: 24,
            },
          }}
        >
          <Button type="primary" htmlType="submit">
            Confirm Password
          </Button>
        </Form.Item>
      </Form>
      <Row>
        <Col 
          md={{
            offset: 8,
            span: 16,
          }}
          xs={{
            span: 24
          }}
        >
          <Link className="text-link" to="/login">Go to Login page</Link>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmPasswordReset;
