import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Space} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

import './styles/Home.css';

const PrivacyPolicy = () => {
    
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(auth.loginDetails) {
          navigate('/transactions')
        }
      }, []);
        
    return (
        <div>
            <iframe src="https://docs.google.com/document/d/e/2PACX-1vQkgvw9IIHLk90Lig-ZuU1mTqzY-OmVAjARH-X3iLfPCKwhbTbqXjdcT1-JMQGHJ2r5oLsGaa2sV5N-/pub?embedded=true"
              width="800" height="1200"
            >
            </iframe>
        </div>

    )
}

export default PrivacyPolicy;