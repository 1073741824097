import {createSlice} from '@reduxjs/toolkit';
import {getCoinbaseUserAuth} from '../actions/coinbase.action';
import { PURGE } from 'redux-persist';

// 2023-10-24 GK - creates a reducer to manage the coinbase state

const initialState = {
    coinbaseUserAuth: {
        data: null,
        status: 'idle',
        error: null
    },

};

export const coinbaseSlice = createSlice({
    name: 'coinbase',
    initialState,
    reducers: {
        
    },
    extraReducers(builder){
        builder
            .addCase(getCoinbaseUserAuth.pending, (state, action) => {
                state.coinbaseUserAuth.status = 'loading'
            })
            .addCase(getCoinbaseUserAuth.fulfilled, (state, action) => {
                state.coinbaseUserAuth.status = 'succeeded';
                state.coinbaseUserAuth.data = action.payload;
            })
            .addCase(getCoinbaseUserAuth.rejected, (state, action) => {
                state.coinbaseUserAuth.status = 'failed';
                state.coinbaseUserAuth.error = action.error.message
            })
            .addCase(PURGE, () => {
                return initialState;
            })
    }
});

export default coinbaseSlice.reducer;